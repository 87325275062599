// @flow

import { v4 } from 'uuid';
import React, { useState } from 'react';
import type { Node } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import Lazyload from 'react-lazyload';
import { Row, Col } from '@riseart/antd-provider';
import { BasePlaceholder } from '@riseart/common';
import { ActivitySimpleCard } from '@riseart/activity';
import { activity as ENUM_ACTIVITY } from 'Enum';
import { Query } from 'shared_services/apollo/Query';
import { useStoreCode } from 'shared_services/redux/hooks/useStoreCode';
import { localeSelector } from 'shared_services/redux/selectors/locale';
import { HomeTrendingPlaceholder } from 'shared_components/pages/home/sections/trending/Placeholder';
import { HomeTrendingSection } from 'shared_components/pages/home/sections/trending/Section';
import { PicturePlaceholder } from 'shared_components/common/artdirection/picture/Picture';
import { FavoritesToggleMutation } from 'shared_data/providers/queries/FavoritesToggleMutation';
import { ActivityRendererMapper } from 'shared_services/riseart/utils/ActivityUtils';
import { DataProviderPreloaderPage } from 'shared_components/data/providers/preloaders/Page';
import FILTER_TRENDING_ACTIVITIES from 'shared_data/queries/activities/filterTrending.graphql';

type Props = { itemsCount: number };

/**
 * HomeTrending
 *
 * @param {Props} props
 * @returns {Node}
 */
export const HomeTrending = ({ itemsCount = 12 }: Props): Node => {
  const intl = useIntl();
  const store = useStoreCode();
  const locale = useSelector(localeSelector);
  const [loaderUniqueId] = useState({ loaderUniqueId: v4() });
  const TrendingPlaceholder = (
    <HomeTrendingSection>
      <HomeTrendingPlaceholder itemsCount={itemsCount} />
    </HomeTrendingSection>
  );

  return (
    <Lazyload placeholder={TrendingPlaceholder} offset={500}>
      <Query
        query={FILTER_TRENDING_ACTIVITIES}
        variables={{ items: itemsCount, store }}
        ssr={false}
        skip={!itemsCount || !store}
      >
        {({ loading, data }) => (
          <DataProviderPreloaderPage uniqueId={loaderUniqueId} attach={loading}>
            {!loading &&
            data &&
            data.filterActivityTrending &&
            data.filterActivityTrending.items ? (
              <HomeTrendingSection>
                <Row type="flex" justify="start">
                  {data.filterActivityTrending.items.map((item, index) => {
                    const cardData =
                      ActivityRendererMapper[item.type] &&
                      ActivityRendererMapper[item.type](
                        item,
                        intl.formatMessage,
                        ENUM_ACTIVITY.image.type.TYPE_SQUARE,
                        null,
                        ENUM_ACTIVITY.feed.FEED_TRENDING,
                        locale,
                      );

                    if (!cardData) {
                      return null;
                    }

                    return (
                      <Col key={item.id} xs={index > 5 ? 0 : 12} sm={6} lg={4}>
                        <ActivitySimpleCard
                          title={intl.formatMessage({
                            id: `components.activity.trending.labels.${cardData.type}`,
                            defaultMessage: '',
                          })}
                          actionControls={
                            cardData.objectArtId ? (
                              <FavoritesToggleMutation artId={cardData.objectArtId} />
                            ) : null
                          }
                          linkProps={
                            cardData.objectUrl
                              ? { title: cardData.altTitle, to: cardData.objectUrl }
                              : null
                          }
                          picture={
                            cardData.objectImage ? (
                              <PicturePlaceholder
                                lazyload
                                alt={cardData.altTitle}
                                image={cardData.objectImage}
                                type="grid.smallVariant2"
                                artDirectionKey={cardData.artDirectionKey}
                              />
                            ) : (
                              <BasePlaceholder />
                            )
                          }
                        />
                      </Col>
                    );
                  })}
                </Row>
              </HomeTrendingSection>
            ) : (
              TrendingPlaceholder
            )}
          </DataProviderPreloaderPage>
        )}
      </Query>
    </Lazyload>
  );
};
