// @flow

import React from 'react';
import type { Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { Section, SectionContent, Wrapper } from '@riseart/layout';

import {
  homeTrendingCls,
  homeTrendingArrowCls,
} from 'shared_components/pages/home/sections/trending/Trending.less';

type Props = { children: Node };

/**
 * HomeTrendingSection
 *
 * @param {Props} props
 * @returns {Node}
 */
export const HomeTrendingSection = ({ children }: Props): Node =>
  children ? (
    <Section>
      <Wrapper>
        <SectionContent
          className={homeTrendingCls}
          title={
            <FormattedMessage id="components.activity.trending.title">
              {(title: string) => title}
            </FormattedMessage>
          }
          subTitle={
            <FormattedMessage id="components.activity.trending.subTitle">
              {(title: string) => title}
            </FormattedMessage>
          }
          headingProps={{ tag: 'h2', level: 2, align: 'center', uppercase: true }}
          headingSubTitleProps={{ tag: 'h3', level: 5, align: 'center' }}
        >
          <span className={homeTrendingArrowCls} />
          {children}
        </SectionContent>
      </Wrapper>
    </Section>
  ) : null;
