// @flow

import React from 'react';
import type { Node } from 'react';
import { Row, Col } from '@riseart/antd-provider';
import { BasePlaceholder } from '@riseart/common';

type Props = {
  itemsCount?: number,
};

/**
 * HomeTrendingPlaceholder
 *
 * @param {Props} props
 * @returns {Node}
 */
export const HomeTrendingPlaceholder = ({ itemsCount = 12 }: Props): Node => (
  <Row type="flex" justify="start">
    {Array.apply(0, Array(itemsCount)).map((item, index) => (
      <Col key={index} xs={index > 5 ? 0 : 12} sm={6} lg={4}>
        <BasePlaceholder />
      </Col>
    ))}
  </Row>
);
